import { isRouteErrorResponse } from "@remix-run/react";

export const getErrorMessage = (error: unknown) => {
  if (isRouteErrorResponse(error)) {
    return `${error.status} ${error.statusText}:\n\t${error.data}`;
  }
  if (error instanceof Error) {
    const details = error.stack ?? error.message;
    if (details.startsWith(error.message)) {
      return details;
    }
    return `${error.message}\n${error.stack ?? ""}`;
  }
  return JSON.stringify(error, undefined, 2);
};
